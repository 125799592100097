<template>
  <div class="m-3 wrapper  wrapper--default">
      <h2 class="text-center">Stadagen per bu</h2>
      <Loading v-if="loading" />
    <div v-if="!loading" class="d-flex flex-row">
      <div class="flex-grow-1 p-2">
        <StockStageDaysTable
          :table_data="stock_data_vans"
          :table_style="'210'"
        />
      </div>
      <div class="flex-grow-1 p-2">
        <StockStageDaysTable
          :table_data="stock_data_trucks"
          :table_style="'200'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StockStageDaysTable from "@/components/StockStageDaysTable.vue";
import Loading from "@/components/Loading.vue"
import request from "@/functions/request.js";

export default {
  components: { StockStageDaysTable, Loading },
  data: () => ({
    stock_data_vans: null,
    stock_data_trucks: null,
    loading: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
        this.loading = true;
      request(`stock-per-bu`, "GET").then(
        ({ stock_data_vans, stock_data_trucks }) => {
          this.stock_data_vans = stock_data_vans;
          this.stock_data_trucks = stock_data_trucks;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style></style>
